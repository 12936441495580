const firebaseConfig = {
  apiKey: "AIzaSyC21-c6I8GjSdbvvEH2sHUz4UsYYPEyrVQ",
  authDomain: "loving-allness.firebaseapp.com",
  databaseURL: "https://loving-allness.firebaseio.com",
  projectId: "loving-allness",
  storageBucket: "loving-allness.appspot.com",
  messagingSenderId: "965364786148",
  appId: "1:965364786148:web:6a89cec38cca745e915df1"
};

export default firebaseConfig;
